import { changeCountryCode, getCurrentCountryCodeFromURL } from 'Util/countryChangeUtility';

/** @namespace Inov8/Util/CountryRedirectUtility */
export class CountryRedirectUtility {
    static checkAndRedirectIfNeeded() {
        const urlCountryCode = getCurrentCountryCodeFromURL();
        const selectedCountry = localStorage.getItem('selectedCountry');

        // Check if the country from the URL is 'uk' and the selected country is not empty or 'uk'
        if (urlCountryCode === 'uk' && selectedCountry && selectedCountry !== 'uk') {
            changeCountryCode(selectedCountry);
        }
    }
}

export default CountryRedirectUtility;
