import {
    FallbackComponent as SourceFallbackComponent,
} from 'SourceComponent/Fallback/Fallback.component';

import './Fallback.style';

/** @namespace Inov8/Component/Fallback/Component */
export class FallbackComponent extends SourceFallbackComponent {
    // TODO implement logic
}

export default FallbackComponent;
