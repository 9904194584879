import { ImageState } from '@scandipwa/scandipwa/src/component/Image/Image.config';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    ImageComponent as SourceImageComponent,
} from 'SourceComponent/Image/Image.component';

/** @namespace Inov8/Component/Image/Component */
export class ImageComponent extends SourceImageComponent {
    renderStyledImage(): ReactElement {
        const {
            alt,
            src,
            style,
            title,
            mix,
        } = this.props;
        const {
            imageStatus,
            isLazyLoading,
        } = this.state;

        const imgSizes: { height?: number; width?: number } = {
            height: undefined,
            width: undefined,
        };

        const imageElement = document.querySelector((`.${mix.block }-${ mix.elem}`));
        const imageElementHeight = Number(imageElement?.getBoundingClientRect().height);
        const imageElementWidth = Number(imageElement?.getBoundingClientRect().width);

        imgSizes.height = this.image.current?.clientHeight;
        imgSizes.width = this.image.current?.clientWidth;
        const imgHeight = !this.image.current?.clientHeight ? Math.round(imageElementHeight) : this.image.current?.clientHeight;
        const imgWidth = !this.image.current?.clientWidth ? Math.round(imageElementWidth) : this.image.current?.clientWidth;

        return (
            <img
              block="Image"
              elem="Image"
              src={ `${ src }?width=${ imgWidth }&height=${ imgHeight }` || '' }
              alt={ alt }
              ref={ this.image }
              mods={ { isLoading: imageStatus === ImageState.IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              height={ imgHeight }
              width={ imgWidth }
              loading={ isLazyLoading ? 'lazy' : 'eager' }
            />
        );
    }
}

export default ImageComponent;
