import ContentWrapper from 'SourceComponent/ContentWrapper';
import {
    SomethingWentWrongComponent as SourceSomethingWentWrongComponent,
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
import { ReactElement } from 'SourceType/Common.type';

/** @namespace Inov8/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrongComponent {
    render(): ReactElement {
        const { onClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    <h3>{ __('Please try to refresh the page or click below to go back to the homepage') }</h3>
                    { /* eslint-disable-next-line react/forbid-elements */ }
                    <a
                      href="/"
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Back to homepage') }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
