/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import Field from 'SourceComponent/Field';
import { FieldType } from 'SourceComponent/Field/Field.config';
import {
    MyAccountCreateAccountComponent as SourceMyAccountCreateAccountComponent,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import { ReactElement } from 'SourceType/Common.type';
import history from 'SourceUtil/History';
import { ValidationInputType } from 'SourceUtil/Validator/Config';

import './MyAccountCreateAccount.style';

/** @namespace Inov8/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccountComponent {
    renderCreateAccountPersonalInfoFields(): ReactElement {
        const { newsletterActive } = this.props;
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;
        const activityPreferences = [
            {label: __('Run'), value: 'run' },
            {label: __('Gym'), value: 'gym' },
            {label: __('Walk'), value: 'walk' },
        ];

        return (
            <>
            <fieldset block="MyAccountOverlay" elem="Legend">
                <legend block="MyAccountOverlay" elem="PersonalInfoLegend">{ __('Personal Information') }</legend>
                <div block="container-grid">
                <Field
                  type={ FieldType.TEXT }
                  label={ __('First Name') }
                  attr={ {
                      id: 'firstname',
                      name: 'firstname',
                      defaultValue: firstName,
                      placeholder: __('Your first name'),
                      autoComplete: 'given-name',
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: ValidationInputType.ALPHASPACE,
                      isRequired: true,
                  } }
                  addRequiredTag
                />

                <Field
                  type={ FieldType.TEXT }
                  label={ __('Last Name') }
                  attr={ {
                      id: 'lastname',
                      name: 'lastname',
                      defaultValue: lastName,
                      placeholder: __('Your last name'),
                      autoComplete: 'family-name',
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: ValidationInputType.ALPHASPACE,
                      isRequired: true,
                  } }
                  addRequiredTag
                />
                </div>
                { this.renderVatNumberField() }

                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
            </fieldset>
            <div block="MyAccountOverlay" elem="ActivityPreferences">
            { activityPreferences.map((preference) => (
                <Field
                  key={ preference.value } // Make sure to provide a unique key
                  type={ FieldType.CHECKBOX }
                  label={ preference.label }
                  attr={ {
                      name: `activity_preference_${ preference.value }`,
                      defaultValue: 1,
                  } }
                />
            )) }
            </div>
            </>
        );
    }
}

export default MyAccountCreateAccountComponent;
