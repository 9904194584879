import { ReactElement } from 'react';

import {
    MyAccountForgotPasswordSuccessComponent as SourceMyAccountForgotPasswordSuccessComponent,
} from 'SourceComponent/MyAccountForgotPasswordSuccess/MyAccountForgotPasswordSuccess.component';

import './MyAccountForgotPasswordSuccess.style';

/** @namespace Inov8/Component/MyAccountForgotPasswordSuccess/Component */
export class MyAccountForgotPasswordSuccessComponent extends SourceMyAccountForgotPasswordSuccessComponent {
    // TODO implement logic
    render(): ReactElement {
        const { state, handleSignIn, submittedEmail } = this.props;

        return (
            <article
              aria-labelledby="forgot-password-success"
              block="MyAccountOverlay"
              elem="Additional"
              mods={ { state } }
            >
                <div block="forgotPasswordTitle">Check Your Email</div>
                <p id="forgot-password-success">
                    { /* eslint-disable-next-line max-len */ }
                    { __('If there is an account associated with %s you will receive an email with a link to reset your password', submittedEmail) }
                </p>
                <button
                  block="Button back-to-log"
                  onClick={ handleSignIn }
                >
                    { __('Back to Login') }
                </button>
            </article>
        );
    }
}

export default MyAccountForgotPasswordSuccessComponent;
