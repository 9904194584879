import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import {
    MyAccountSignInComponent as SourceMyAccountSignInComponent,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { ReactElement } from 'Type/Common.type';
import { ValidationInputType } from 'Util/Validator/Config';

import './MyAccountSignIn.style';

/** @namespace Inov8/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignInComponent {
    // TODO implement logic

    renderSignInForm(): ReactElement {
        const {
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout,
            handleEmailInput,
            isLoading,
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                <div block="sign-in-container">
                <Field
                  label={ __('Email') }
                  type={ FieldType.EMAIL }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Your email address'),
                      defaultValue: emailValue,
                      autoComplete: isCheckout ? 'off' : 'email',
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: ValidationInputType.EMAIL,
                  } }
                  events={ { onChange: handleEmailInput } }
                  addRequiredTag
                />
                <Field
                  label={ __('Password') }
                  type={ FieldType.PASSWORD }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter your password'),
                      autoComplete: 'current-password',
                  } }
                  validateOn={ isCheckout ? ['onSubmit'] : ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: ValidationInputType.PASSWORD,
                  } }
                  addRequiredTag
                />
                </div>
                <button
                  type="button"
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                  onClick={ handleForgotPassword }
                >
                    { __('Forgot password?') }
                </button>
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Sign in') }</button>
                </div>
                <Loader isLoading={ isLoading } />
            </Form>
        );
    }
}

export default MyAccountSignInComponent;
