/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    RECAPTCHA_API_PARAMS,
    RECAPTCHA_API_URL,
    RECAPTCHA_SCRIPT_ID,
} from '../../config/ReCaptcha.config';
import {
    LocationEnum,
    LocationsArrayType,
    ReCaptchaConfigArrayType,
} from '../../types/ReCaptcha.types';
import {
    getTypeForLocation, isCheckbox, loadScript, setTokens,
} from '../../util/ReCaptcha.util';
import ReCaptcha from './ReCaptcha.component';

/** @namespace Scandiweb/GoogleRecaptcha/Component/ReCaptcha/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    locations: state.ConfigReducer.googleReCaptcha.locations,
    types: state.ConfigReducer.googleReCaptcha.types_config,
});

/** @namespace Scandiweb/GoogleRecaptcha/Component/ReCaptcha/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/GoogleRecaptcha/Component/ReCaptcha/Container */
export class ReCaptchaContainer extends PureComponent {
    static propTypes = {
        locations: LocationsArrayType.isRequired,
        types: ReCaptchaConfigArrayType.isRequired,
        currentLocation: LocationEnum.isRequired,
    };

    containerFunctions = {};

    componentDidMount() {
        const currentLocation = this.getCurrentLocation();

        if (!currentLocation) {
            return;
        }

        // vvv Render after the script has loaded.
        this.onScriptLoad(() => {
            this.explicitRender();
        });

        loadScript(
            RECAPTCHA_SCRIPT_ID,
            `${RECAPTCHA_API_URL}${RECAPTCHA_API_PARAMS}`
        );

        // vvv Render reCAPTCHA for new instances.
        this.explicitRender();
    }

    componentWillUnmount() {
        const currentLocation = this.getCurrentLocation();

        if (!currentLocation) {
            return;
        }

        const widgetId = window.sessionStorage.getItem(`${currentLocation.id}_sessionid`);
        const res = window.grecaptcha.getResponse(widgetId);

        try {
            if (res) {
                window.grecaptcha.reset(widgetId);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('reCAPTCHA already reset, no further actions needed!');
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class
    containerProps = () => {
        const location = this.getCurrentLocation();

        return {
            location,
            type: this.getCurrentType(location),
        };
    };

    explicitRender() {
        const location = this.getCurrentLocation();
        const {
            type,
            sitekey,
            theme,
            size,
            badge_position,
            hl,
        } = this.getCurrentType(location) || {};

        if (!type) {
            return;
        }

        // vvv ID that uniquely identifies current instance of reCAPTCHA.
        // There could be multiple instances on the same page.
        const widgetId = window.grecaptcha?.render(location.id, {
            // type: RECAPTCHA_TYPES_MAP[type],
            sitekey,
            theme,
            size,
            hl,
            badge: badge_position,
            callback: this.getCallbackForRecaptcha,
        });

        window.sessionStorage.setItem(`${location.id}_sessionid`, widgetId);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class
    getCallbackForRecaptcha = (token) => {
        const { locations } = this.props;

        const { id: locationId } = this.getCurrentLocation();
        const type = getTypeForLocation(locations, locationId);

        if (isCheckbox(type)) {
            setTokens(token, locationId);
        } else {
            window.gRecaptchaCallback(token);
        }
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class
    onScriptLoad = (cb) => {
        const location = this.getCurrentLocation();

        // vvv If reCAPTCHA is disabled for all locations
        // then no reason to load script.
        if (!location) {
            return null;
        }

        window.recaptchaOnLoad = () => {
            cb();
        };

        return null;
    };

    getCurrentLocation() {
        const {
            locations,
            currentLocation,
        } = this.props;

        return locations.find(({ id }) => (id === currentLocation));
    }

    getCurrentType(location) {
        const {
            types,
        } = this.props;

        if (!location) {
            return null;
        }

        return types.find(({ type }) => (type === location.type));
    }

    render() {
        return (
            <ReCaptcha
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReCaptchaContainer);
