/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import { PERSONAL_DATA_FILE_NAME } from '../../config';
import { GDPRContext } from '../../context/GDPR.context';
import DownloadButtonComponent from './DownloadCSVButton.component';

/** @namespace Scandiweb/AmastyGdpr/Component/DownloadCSVButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/DownloadCSVButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/DownloadCSVButton/Container */
export class DownloadCSVButtonContainer extends DataContainer {
    static propTypes = {
        orderId: PropTypes.string,
    };

    static contextType = GDPRContext;

    containerProps() {}

    containerFunctions = {
        onClickHandler: this.onClickHandler.bind(this),
    };

    createFile(text) {
        const blob = new Blob([text], {
            type: 'text/csv;charset=utf8;',
        });

        // vvv creates hidden link.
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', PERSONAL_DATA_FILE_NAME);
        element.style.display = '';

        // vvv programmatically click on the button then remove it.
        element.click();
        document.body.removeChild(element);
    }

    async onClickHandler() {
        const { handleDownloadPersonalData } = this.context;
        const { orderId } = this.props;
        try {
            const data = await handleDownloadPersonalData(orderId);

            this.createFile(data);
        } catch (e) {
            console.warn('download csv error: ', e);
        }
    }

    render() {
        return (
            <DownloadButtonComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCSVButtonContainer);
