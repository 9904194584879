import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    MyAccountAddressTableComponent as SourceMyAccountAddressTableComponent,
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.style';

/** @namespace Inov8/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTableComponent {
    renderActions(): ReactElement {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address,
        } = this.props;

        if (!('default_shipping' in address)) {
            return null;
        }

        const { default_billing, default_shipping } = address;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <button
                  block="Button"
                  elem="Secondary"
                  onClick={ onEditClick }
                >
                    { __('Edit address') }
                </button>
                <button
                  block="Button"
                  elem="Secondary"
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }
}

export default MyAccountAddressTableComponent;
