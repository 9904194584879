/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/CheckScope/Query */
export class CheckScopeQuery {
    getCheckScopeMutation(scope) {
        const mutation = new Field('gdprCheckScope')
            .addFieldList(this.getCheckScopeFieldList());

        this.addCheckScopeMutationArguments(mutation, scope);

        return mutation;
    }

    addCheckScopeMutationArguments(mutation, scope) {
        return mutation.addArgument('scope', 'String', scope);
    }

    getCheckScopeFieldList() {
        return [
            'name',
            'consentCode',
            'consentText',
            'isEnabled',
            'isRequired',
            'link',
        ];
    }
}

export default new CheckScopeQuery();
