import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CONFIRMATION_REQUIRED,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { ORDER_ID } from 'SourceComponent/MyAccountOrder/MyAccountOrder.config';
import { MyAccountPageState } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';
import { CreateAccountOptions } from 'SourceQuery/MyAccount.type';
import { NotificationType } from 'SourceStore/Notification/Notification.type';
import { FieldData } from 'SourceUtil/Form/Form.type';
import transformToNameValuePair from 'SourceUtil/Form/Transform';
import history from 'SourceUtil/History';
import { RootState } from 'SourceUtil/Store/Store.type';

/** @namespace Inov8/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    async onSuccess(form: HTMLFormElement, fields: FieldData[]): Promise<void> {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile,
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            activity_preference_run,
            activity_preference_gym,
            activity_preference_walk,
        } = transformToNameValuePair<Record<string, string | number | boolean>>(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                taxvat,
                activity_preference_run,
                activity_preference_gym,
                activity_preference_walk,
            },
            password,
            orderID: sessionStorage.getItem(ORDER_ID),
        } as CreateAccountOptions;

        if (is_subscribed) {
            customerData.customer.is_subscribed = !!is_subscribed;
        }

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace Inov8/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch */
                () => process.exit(1),
            );

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                setSignInState(MyAccountPageState.STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        NotificationType.SUCCESS,
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.'),
                    );
                    history.push('/default/customer/account/login');
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
