/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { RouterItemType } from 'Component/Router/Router.config';

import GDPR from '../component/GDPR';
import { GDPR_NOTICE, GDPR_NOTICE_POSITION } from '../config/GDPR.config';
import { cleanDuplicatesFromAfterItemsType } from '../util';

/**
 * overriding to plugin the GDPR component on every route.
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const afterItemsType = (member) => cleanDuplicatesFromAfterItemsType([
    ...member,
    {
        component: <GDPR />,
        position: GDPR_NOTICE_POSITION,
        name: GDPR_NOTICE,
    }]);

export default {
    'Component/Router/Component': {
        'member-property': {
            [RouterItemType.AFTER_ITEMS_TYPE]: afterItemsType,
        },
    },
};
