/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { CheckboxItemDataType } from '../../type/gdpr.type';
import {
    removeUserAcceptanceToCheckboxesInBrowserDB,
    storeUserAcceptanceToCheckboxesInBrowserDB,
} from '../../util/GDPR.util';
import CheckboxComponent from './Checkbox.component';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container */
export class CheckboxContainer extends DataContainer {
    static propTypes = {
        data: CheckboxItemDataType,
        scope: PropTypes.string.isRequired,
    };

    state = {
        consentCheckbox: {
            name: '',
            isChecked: '',
        },
    };

    handleCheckboxChange(event) {
        const { data: { name } } = this.props;
        const { target: { checked } } = event;

        this.setState({
            consentCheckbox: {
                name,
                isChecked: checked,
            },
        });

        if (checked) {
            storeUserAcceptanceToCheckboxesInBrowserDB(name);
        }

        removeUserAcceptanceToCheckboxesInBrowserDB(name);
    }

    containerProps() {
        const {
            scope,
            data,
        } = this.props;
        const {
            consentCheckbox,
        } = this.state;

        return {
            scope,
            data,
            consentCheckbox,
        };
    }

    containerFunctions = {
        handleCheckboxChange: this.handleCheckboxChange.bind(this),
    };

    render() {
        return (
            <CheckboxComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxContainer);
