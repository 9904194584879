/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import GDPRComponent from './GDPR.component';

/** Used to fetch the state from redux to our container */
/** @namespace Scandiweb/AmastyGdpr/Component/GDPR/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    backgroundColor: state.ConfigReducer.gdpr__classic__background_color,
    cookiesBarLocation: state.ConfigReducer.gdpr__classic__cookies_bar_location,
    linksColor: state.ConfigReducer.gdpr__classic__links_color,
    notificationText: state.ConfigReducer.gdpr__classic__notification_text,
    policyTextColor: state.ConfigReducer.gdpr__classic__policy_text_color,

    acceptBtnOrder: state.ConfigReducer.gdpr__classic__accept_button__button_order,
    acceptBtnColor: state.ConfigReducer.gdpr__classic__accept_button__button_color,
    acceptBtnHoverColor: state.ConfigReducer.gdpr__classic__accept_button__button_color_hover,
    acceptBtnText: state.ConfigReducer.gdpr__classic__accept_button__button_text,
    acceptBtnTextColor: state.ConfigReducer.gdpr__classic__accept_button__text_color,
    acceptBtnTextHoverColor: state.ConfigReducer.gdpr__classic__accept_button__text_color_hover,

    declineBtnIsEnabled: state.ConfigReducer.gdpr__classic__decline_button__enable,
    declineBtnOrder: state.ConfigReducer.gdpr__classic__decline_button__button_order,
    declineBtnColor: state.ConfigReducer.gdpr__classic__decline_button__button_color,
    declineBtnHoverColor: state.ConfigReducer.gdpr__classic__decline_button__button_color_hover,
    declineBtnText: state.ConfigReducer.gdpr__classic__decline_button__button_text,
    declineBtnTextColor: state.ConfigReducer.gdpr__classic__decline_button__text_color,
    declineBtnTextHoverColor: state.ConfigReducer.gdpr__classic__decline_button__text_color_hover,

    settingsBtnOrder: state.ConfigReducer.gdpr__classic__settings_button__button_order,
    settingsBtnColor: state.ConfigReducer.gdpr__classic__settings_button__button_color,
    settingsBtnHoverColor: state.ConfigReducer.gdpr__classic__settings_button__button_color_hover,
    settingsBtnText: state.ConfigReducer.gdpr__classic__settings_button__button_text,
    settingsBtnTextColor: state.ConfigReducer.gdpr__classic__settings_button__text_color,
    settingsBtnTextHoverColor: state.ConfigReducer.gdpr__classic__settings_button__text_color_hover,
});

/** Connected to the container to be able to dispatch functions Used to dispatch action to redux */
/** @namespace Scandiweb/AmastyGdpr/Component/GDPR/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/GDPR/Container */
export class GDPRContainer extends DataContainer {
    /** defines the props for this component */
    static propTypes = {
        backgroundColor: PropTypes.string,
        cookiesBarLocation: PropTypes.string,
        linksColor: PropTypes.string,
        notificationText: PropTypes.string,
        policyTextColor: PropTypes.string,

        acceptBtnOrder: PropTypes.string,
        acceptBtnColor: PropTypes.string,
        acceptBtnHoverColor: PropTypes.string,
        acceptBtnText: PropTypes.string,
        acceptBtnTextColor: PropTypes.string,
        acceptBtnTextHoverColor: PropTypes.string,

        declineBtnIsEnabled: PropTypes.string,
        declineBtnColor: PropTypes.string,
        declineBtnHoverColor: PropTypes.string,
        declineBtnOrder: PropTypes.string,
        declineBtnText: PropTypes.string,
        declineBtnTextColor: PropTypes.string,
        declineBtnTextHoverColor: PropTypes.string,

        settingsBtnOrder: PropTypes.string,
        settingsBtnColor: PropTypes.string,
        settingsBtnHoverColor: PropTypes.string,
        settingsBtnText: PropTypes.string,
        settingsBtnTextColor: PropTypes.string,
        settingsBtnTextHoverColor: PropTypes.string,
    };

    static contextType = GDPRContext;

    /**
     * gets the passed styles from props and returns it as an object for their specific
     * css variable.
     * */
    getStyle() {
        const {
            backgroundColor,
            cookiesBarLocation,
            policyTextColor,
            linksColor,

            acceptBtnColor,
            acceptBtnHoverColor,
            acceptBtnOrder,
            acceptBtnTextColor,
            acceptBtnTextHoverColor,

            declineBtnColor,
            declineBtnHoverColor,
            declineBtnOrder,
            declineBtnTextColor,
            declineBtnTextHoverColor,

            settingsBtnColor,
            settingsBtnHoverColor,
            settingsBtnOrder,
            settingsBtnTextColor,
            settingsBtnTextHoverColor,
        } = this.props;

        const consentLocation = (!cookiesBarLocation || cookiesBarLocation === '0')
            ? 'FlexEnd'
            : 'FlexStart';

        return {
            '--gdpr-background-color': backgroundColor,
            '--gdpr-cookies-bar-location': consentLocation,
            '--gdpr-policy-text-color': policyTextColor,
            '--gdpr-links-color': linksColor,

            '--gdpr-accept-btn-order': acceptBtnOrder,
            '--gdpr-accept-btn-color': acceptBtnColor,
            '--gdpr-accept-btn-color-hover': acceptBtnHoverColor,
            '--gdpr-accept-btn-text-color': acceptBtnTextColor,
            '--gdpr-accept-btn-text-hover-color': acceptBtnTextHoverColor,

            '--gdpr-decline-btn-order': declineBtnOrder,
            '--gdpr-decline-btn-color': declineBtnColor,
            '--gdpr-decline-btn-hover-color': declineBtnHoverColor,
            '--gdpr-decline-btn-text-color': declineBtnTextColor,
            '--gdpr-decline-btn-text-hover-color': declineBtnTextHoverColor,

            '--gdpr-settings-btn-order': settingsBtnOrder,
            '--gdpr-settings-btn-color': settingsBtnColor,
            '--gdpr-settings-btn-hover-color': settingsBtnHoverColor,
            '--gdpr-settings-btn-text-color': settingsBtnTextColor,
            '--gdpr-settings-btn-text-hover-color': settingsBtnTextHoverColor,
        };
    }

    /**
     * returns the props that should be passed to the component.
     */
    containerProps() {
        const {
            acceptBtnText,
            declineBtnIsEnabled,
            declineBtnText,
            notificationText,
            settingsBtnText,
        } = this.props;

        const {
            isCookiesBarVisible,
            isLoading,
            showSettingsModal,
        } = this.context;

        return {
            acceptBtnText,
            cookieBarStyle: this.getStyle(),
            declineBtnIsEnabled,
            declineBtnText,
            isCookiesBarVisible,
            isLoading,
            notificationText,
            settingsBtnText,
            showSettingsModal,
        };
    }

    containerFunctions= {
        handleAcceptCookies: this.handleAcceptCookies.bind(this),
        handleDeclineCookies: this.handleDeclineCookies.bind(this),
    };

    handleAcceptCookies() {
        const {
            handleAcceptCookies: accept,
            dismissCookiesBar,
        } = this.context;

        accept();
        dismissCookiesBar();
    }

    handleDeclineCookies() {
        const {
            dismissCookiesBar,
            handleDeclineCookies: decline,
        } = this.context;

        decline();
        dismissCookiesBar();
    }

    componentDidMount() {
        const { showCookiesBar } = this.context;
        showCookiesBar();
    }

    render() {
        const { isExtensionAllowed } = this.context;

        if (!isExtensionAllowed) {
            return null;
        }

        return (
            <GDPRComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GDPRContainer);
