import { PureComponent } from 'react';

import ContentWrapper from 'SourceComponent/ContentWrapper';
import Image from 'SourceComponent/Image';
import { ReactElement } from 'SourceType/Common.type';
import { getStoreState } from 'SourceUtil/Store';

import {
    PAYMENT_METHODS,
} from './PaymentMethodImg.config';
import { PaymentMethodImgComponentProps } from './PaymentMethodImg.type';

import './PaymentMethodImg.style';

/** @namespace Inov8/Component/PaymentMethodImg/Component */
export class PaymentMethodImgComponent extends PureComponent<PaymentMethodImgComponentProps> {
    render():ReactElement {
        // const { ConfigReducer: { secure_base_media_url, code } = {} } = getStoreState();
        const { ConfigReducer: { code } = {} } = getStoreState();

        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'PaymentMethodImg' } }
              label=""
            >

                { PAYMENT_METHODS.map((paymentMethod) => (
                    code && paymentMethod.available_in.includes(code) && (
                            <Image
                              key={ paymentMethod.title }
                              mix={ { block: 'Footer', elem: 'PaymentMethods' } }
                              src={ `https://www.inov8.com/media/${paymentMethod.src}` }
                              title={ paymentMethod.title }
                            />
                    )
                )) }
            </ContentWrapper>

        );
    }
}

export default PaymentMethodImgComponent;
