import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
