import { Dispatch } from 'redux';

import { OrderItem } from 'Query/Order.type';
import {
    OrderDispatcher as SourceOrderDispatcher,
} from 'SourceStore/Order/Order.dispatcher';
import { fetchQuery } from 'Util/Request/Query';

import OrderQuery from '../../query/Order.query';

/** @namespace Inov8/Store/Order/Dispatcher */
export class OrderDispatcher extends SourceOrderDispatcher {
    async getOrderById(dispatch: Dispatch, orderId: number): Promise<OrderItem | null> {
        try {
            const {
                customer: {
                    orders: {
                        items,
                    },
                },
            } = await fetchQuery(OrderQuery.getOrderListV2Query({ orderId }));

            return items[0];
        } catch (error) {
            return null;
        }
    }
}

export default new OrderDispatcher();
