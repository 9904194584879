/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Html from 'SourceComponent/Html';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesItem/Component */
export class CookiesItemComponent extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        isEssential: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
        moreInfoOnClickHandler: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
    };

    renderCheckbox() {
        const {
            onChange,
            groupId,
            isEssential,
            isSelected,
        } = this.props;

        return (
            <Field
              type={ FieldType.CHECKBOX }
              attr={ {
                  id: `${ groupId }_checkbox`,
                  name: `${ groupId }_checkbox`,
                  checked: isSelected,
              } }
              events={ { onChange } }
              isDisabled={ isEssential }
              mods={ { type: 'Select' } }
            />
        );
    }

    renderMoreInfoBtn() {
        const {
            moreInfoOnClickHandler,
        } = this.props;

        return (
            <button
              block="Button"
              mods={ { isHallow: true } }
              type="button"
              onClick={ moreInfoOnClickHandler }
            >
                { __('More Information') }
            </button>
        );
    }

    render() {
        const {
            name,
            description,
        } = this.props;

        return (
             <div block="GroupData" elem="Wrapper">
                <div block="GroupData" elem="Header">
                    <h3>{ name }</h3>
                    { this.renderCheckbox() }
                </div>
                <p block="GroupData" elem="Header">
                    <span block="GroupData" elem="ContentWrapper">
                        <Html content={ description } />
                    </span>
                </p>
                { this.renderMoreInfoBtn() }
             </div>
        );
    }
}

export default CookiesItemComponent;
