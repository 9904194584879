/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Wrapper } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PagebuilderElementType } from '../../type/Elements.type';
import MapComponent from './Map.component';
import {
    DEFAULT_ZOOM_LEVEL,
    escapeHtmlProps,
    parseLocations,
} from './Map.config';

/** @namespace Scandiweb/AdobePageBuilder/Component/Map/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    apiKey: state.ConfigReducer?.pageBuilderConfig?.googleMapsApiKey,
    googleMapsStyle: state.ConfigReducer?.pageBuilderConfig?.googleMapsStyle,
});

/** @namespace Scandiweb/AdobePageBuilder/Component/Map/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/AdobePageBuilder/Component/Map/Container */
export class MapContainer extends PureComponent {
    static propTypes = {
        apiKey: PropTypes.string.isRequired,
        googleMapsStyle: PropTypes.string.isRequired,
        elements: PagebuilderElementType.isRequired,
    };

    containerFunctions = {};

    containerProps() {
        const {
            apiKey,
            googleMapsStyle,
            elements: {
                BaseGoogleMap: {
                    propsBag,
                    Ele,
                },
            },
        } = this.props;

        const mapProps = escapeHtmlProps((propsBag && propsBag[0]) || {});
        const locations = parseLocations(mapProps['data-locations']);

        return {
            element: Ele,
            apiKey,
            locations,
            zoom: DEFAULT_ZOOM_LEVEL,
            googleMapsStyle,

        };
    }

    render() {
        const { apiKey } = this.props;

        if (!apiKey) {
            return '';
        }

        return (
            <div block="GoogleMap" elem="Container">
                <Wrapper apiKey={ apiKey }>
                        <MapComponent
                          { ...this.containerFunctions }
                          { ...this.containerProps() }
                        />
                </Wrapper>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
