/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_POPUP_TITLE = 'UPDATE_POPUP_TITLE';

/** @namespace Scandiweb/AmastyFreeGift/Store/Popup/Action/updatePopupTitle */
export const updatePopupTitle = (overlayKey, title) => ({
    type: UPDATE_POPUP_TITLE,
    overlayKey,
    title
});
