import {
    getOrderList as sourceGetOrderList,
    setLoadingStatus as sourceSetLoadingStatus,
} from 'SourceStore/Order/Order.action';

// TODO: implement getOrderList
export const getOrderList = sourceGetOrderList;

// TODO: implement setLoadingStatus
export const setLoadingStatus = sourceSetLoadingStatus;
