import { MetaActionType, MetaStore, UpdateMetaAction } from '@scandipwa/scandipwa/src/store/Meta/Meta.type';
import { Reducer } from 'redux';

import {
    filterData as sourceFilterData,
    getInitialState as sourceGetInitialState,
    updateEveryTime as sourceUpdateEveryTime,
} from 'SourceStore/Meta/Meta.reducer';

// TODO: implement updateEveryTime
export const updateEveryTime = sourceUpdateEveryTime;

// TODO: implement filterData
export const filterData = sourceFilterData;

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement MetaReducer
/** @namespace Inov8/Store/Meta/Reducer/MetaReducer */
export const MetaReducer: Reducer<MetaStore, UpdateMetaAction> = (
    state = getInitialState(),
    action,
) => {
    const { payload = {}, type } = action;
    switch (type) {
    case MetaActionType.UPDATE_META:
        const filteredData = filterData(payload);

        return {
            ...state,
            ...filteredData,
        };

    default:
        return state;
    }
};

export default MetaReducer;
