/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import { OrderTabs } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { BundleOption, OrderProductSelectedOption } from 'Query/Order.type';
import { getStoreState } from 'SourceUtil/Store';
import { ReactElement } from 'Type/Common.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { getOrderItemQtyToArray, getOrderItemRowDiscount } from 'Util/Orders';
import { OrderItemQtyArray } from 'Util/Orders/Orders.type';
import { formatPrice } from 'Util/Price';

import { ORDER_STATUS_TRANSLATION_MAP } from './MyAccountOrderItemsTableRow.config';
import { MyAccountOrderItemsTableRowComponentProps } from './MyAccountOrderItemsTableRow.type';

import './MyAccountOrderItemsTableRow.style';

/** @namespace Inov8/Component/MyAccountOrderItemsTableRow/Component */
export class MyAccountOrderItemsTableRowComponent extends PureComponent<MyAccountOrderItemsTableRowComponentProps> {
    static defaultProps: Partial<MyAccountOrderItemsTableRowComponentProps> = {
        comments: [],
    };

    renderMap = {
        renderOption: this.renderOption.bind(this),
        renderQty: this.renderQty.bind(this),
        renderOptionItem: this.renderOptionItem.bind(this),
        renderEnteredOptionAsRow: this.renderEnteredOptionAsRow.bind(this),
    };

    renderItemPrice(): ReactElement {
        const {
            product: {
                product_sale_price: {
                    value,
                    currency,
                },
            },
            activeTab,
        } = this.props;

        if (activeTab === OrderTabs.ORDER_SHIPMENTS) {
            return null;
        }

        return (
            <>
                { this.renderPrice(value, currency, (__('Price'))) }
            </>
        );
    }

    renderQty([type, qty]: [keyof OrderItemQtyArray, number], index: number): ReactElement {
        const { activeTab } = this.props;

        if (qty === 0) {
            return null;
        }

        if (activeTab === OrderTabs.ORDER_ITEMS) {
            return (
                <li key={ index }>{ `${ORDER_STATUS_TRANSLATION_MAP[ type ]} ${qty}` }</li>
            );
        }

        return (
            <li key={ index }>{ qty }</li>
        );
    }

    renderRowQty(): ReactElement {
        const { product } = this.props;
        const { renderQty } = this.renderMap;

        const qtyArray = Object.entries(getOrderItemQtyToArray(product)) as [keyof OrderItemQtyArray, number][];

        return (
            <ul
              block="MyAccountOrderItemsTableRow"
              elem="QtyList"
            >
                { qtyArray.map(renderQty) }
            </ul>
        );
    }

    renderRowSubtotal(): ReactElement {
        const {
            activeTab,
            product,
        } = this.props;

        if (activeTab === OrderTabs.ORDER_SHIPMENTS || !('row_subtotal' in product)) {
            return null;
        }

        const {
            row_subtotal: {
                value,
                currency,
            } = {},
        } = product;

        return this.renderPrice(value, currency, __('Subtotal'));
    }

    renderPrice<T>(value: T, currency: string | undefined, title: string): ReactElement {
        return (
            <div data-th={ title }>
                <strong>{ formatPrice(Number(value), (currency as GQLCurrencyEnum) || GQLCurrencyEnum.USD) }</strong>
            </div>
        );
    }

    renderSelectedAndEnteredOptions(): ReactElement {
        const {
            selectedOptions,
            enteredOptions,
        } = this.props;
        const { renderOption } = this.renderMap;

        if (!selectedOptions.length && !enteredOptions.length) {
            return null;
        }

        return (
            <>
                { selectedOptions.map(renderOption) }
                { enteredOptions.map(renderOption) }
            </>
        );
    }

    renderNameAndOptions(): ReactElement {
        const {
            product: {
                product_name,
                // product: {
                //     thumbnail,
                // },
                product,
            },
        } = this.props;
        const { ConfigReducer: { secure_base_media_url } = {} } = getStoreState();

        return (
            <>
            { product && product.thumbnail ? (
                <div block="MyAccountOrderItemsTableRow" elem="Image">
            <Image
              // eslint-disable-next-line react/destructuring-assignment
              src={ `${secure_base_media_url }catalog/product${ product.thumbnail.url}` }
              mix={ {
                  block: 'CartItem',
                  elem: 'Picture',
              } }
              ratio={ ImageRatio.IMG_16X9 }
              alt={ `Product ${name} thumbnail.` }
            />
            <img
              style={ { display: 'none' } }
              alt={ product_name }
              // eslint-disable-next-line react/destructuring-assignment
              src={ `${secure_base_media_url }catalog/product${ product.thumbnail.url}` }
            />
                </div>
            ) : null }
            <div
              block="name"
            >
                <div
                  block="MyAccountOrderItemsTableRow"
                  elem="Name"
                >
                    { product_name }
                </div>

                { this.renderSelectedAndEnteredOptions() }

            </div>
            </>
        );
    }

    renderOptionItem(item: BundleOption, isLastOptionItem: boolean): ReactElement {
        const { product: { product_sale_price: { currency } }, product } = this.props;
        const { qty, title, price } = item;

        return (
            <div
              block="MyAccountOrderItemsTableRow"
              elem="EnteredRow"
              mods={ { isLastOptionItem } }
              key={ `${qty}-${title}` }
            >
                <div data-th={ __('Product Name') }>
                    { `${qty} x ${title}` }
                </div>
                <div data-th={ __('SKU') }>{ title }</div>
                { this.renderEnteredOptionPrice(formatPrice(price, currency)) }
                <div
                  block="MyAccountOrderItemsTableRow"
                  elem="EnteredQty"
                  data-th={ __('Qty') }
                >
                    { ('quantity_ordered' in product ? product.quantity_ordered : 1) * qty }
                </div>
            </div>
        );
    }

    renderEnteredOptionPrice(price: string): ReactElement {
        const { activeTab } = this.props;

        if (activeTab === OrderTabs.ORDER_SHIPMENTS) {
            return null;
        }

        return (
            <div
              block="MyAccountOrderItemsTableRow"
              elem="EnteredPrice"
              data-th={ __('Price') }
            >

                <strong>{ price }</strong>
            </div>
        );
    }

    renderEnteredOptionAsRow(option: OrderProductSelectedOption, index: number): ReactElement {
        const { enteredOptions } = this.props;
        const { label, items } = option;
        const { renderOptionItem } = this.renderMap;

        if (!items) {
            return null;
        }

        const isLastOptionItem = enteredOptions.length - 1 === index;

        return (
            <>
                <div
                  block="MyAccountOrderItemsTableRow"
                  elem="EnteredLabel"
                  key={ `${label}-${index}` }
                >
                    <div>
                        <strong>{ label }</strong>
                    </div>
                </div>
                { items.map((item) => renderOptionItem(item, isLastOptionItem)) }
            </>
        );
    }

    renderEnteredOptionsAsRow(): ReactElement {
        const { enteredOptions } = this.props;
        const { renderEnteredOptionAsRow } = this.renderMap;

        if (!enteredOptions.length) {
            return null;
        }

        return enteredOptions.map(renderEnteredOptionAsRow);
    }

    renderOption(option: OrderProductSelectedOption): ReactElement {
        const {
            label,
            items,
            value,
        } = option || [];

        if (items) {
            return null;
        }

        return (
            <div key={ `${label}-${value}` }>
                <div block="mobile-label-container">

                <div
                  block="MyAccountOrderItemsTableRow mobile-label"
                //   elem=" OptionLabel "
                >
                    <strong>{ label }</strong>
                </div>

                { this.renderOptionContent(option) }
                </div>
            </div>
        );
    }

    renderOptionContent(option: OrderProductSelectedOption): ReactElement {
        const {
            value = '',
            linkItems = [],
        } = option;

        if (linkItems && linkItems.length) {
            return linkItems.map(this.renderLink.bind(this));
        }

        return <div block="MyAccountOrderItemsTableRow" elem="OptionValue"><Html content={ value } /></div>;
    }

    renderLink(title: string, index: number): ReactElement {
        return (
            <div
              block="MyAccountOrderItemsTableRow"
              elem="DownloadableLink"
              key={ `${title}-${index}` }
            >
                { title }
            </div>
        );
    }

    renderDiscountAndRowTotal(): ReactElement {
        const {
            activeTab,
            product,
            isMobile,
        } = this.props;

        if (
            activeTab !== OrderTabs.ORDER_REFUNDS
            || !('row_subtotal' in product)
            || !('discounts' in product)
        ) {
            return null;
        }

        const {
            row_subtotal: {
                value: row_subtotal,
                currency,
            } = {},
            discounts = [],
        } = product;

        const totalDiscount = discounts.length ? getOrderItemRowDiscount(discounts) : 0;

        if (isMobile) {
            return (
                <>
                dasdasdsadass
                    { this.renderPrice(
                        -Number(totalDiscount),
                        currency,
                        __('Discount Amount'),
                    ) }
                    { this.renderPrice(
                        Number(row_subtotal) - totalDiscount,
                        currency,
                        __('Row Total'),
                    ) }
                </>
            );
        }

        return (
            <>
                <td><strong>{ formatPrice(-totalDiscount, currency) }</strong></td>
                <td><strong>{ formatPrice(Number(row_subtotal) - totalDiscount, currency) }</strong></td>
            </>
        );
    }

    renderTableRow(): ReactElement {
        const {
            activeTab,
            product: {
                product_sku,
            },
            enteredOptions = [],
            comments,
        } = this.props;

        // Function to extract the number of the SKU
        const extractNumberFromSKU = (sku:any) => {
            const match = sku.match(/^(\d+)/);

            return match ? match[1] : null;
        };

        const skuNumber = extractNumberFromSKU(product_sku);

        const isWithEnteredItems = !!enteredOptions[ 0 ]?.items;
        const lineBefore = !!((activeTab === OrderTabs.ORDER_SHIPMENTS) && (comments.length));

        return (
            <>
                <div
                  block="MyAccountOrderItemsTableRow"
                  elem="RowWrapper"
                  mods={ { isWithEnteredItems, lineBefore } }
                >

                    { this.renderNameAndOptions() }

                    <div block="mobile-label-container">
                        <span block="mobile-label"><strong>SKU:</strong></span>
                        <div data-th={ __('SKU') }>{ skuNumber }</div>
                    </div>

                    <div block="mobile-label-container">
                        <span block="mobile-label">
                            <strong>Price:</strong>
                        </span>
                        { this.renderItemPrice() }
                    </div>

                    <div
                      block="MyAccountOrderItemsTableRow"
                      elem="Qty"
                      data-th={ __('Qty') }
                    >
                        <div block="mobile-label-container">
                            <span block="mobile-label">
                                    <strong>Quanity:</strong>
                            </span>
                                { this.renderRowQty() }
                        </div>
                    </div>

                    <div block="mobile-label-container">
                        <span block="mobile-label"><strong>Subtotal:</strong></span>
                        { this.renderRowSubtotal() }
                    </div>

                    { this.renderDiscountAndRowTotal() }
                </div>

                    { this.renderEnteredOptionsAsRow() }
            </>
        );
    }

    render(): ReactElement {
        return this.renderTableRow();
    }
}

export default MyAccountOrderItemsTableRowComponent;
