/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandiweb/AmastyGdpr/Component/DownloadCSVButton/Component */
export class DownloadCSVButtonComponent extends PureComponent {
    static propTypes = {
        onClickHandler: PropTypes.func.isRequired,
    };

    render() {
        const { onClickHandler } = this.props;

        return (
            <button
              block="Button"
              onClick={ onClickHandler }
            >
                { __('Download CSV') }
            </button>
        );
    }
}

export default DownloadCSVButtonComponent;
