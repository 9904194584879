/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const LocationEnum = PropTypes.oneOf([
    'CUSTOMER_LOGIN',
    'FORGOT_PASSWORD',
    'NEW_CUSTOMER_ACCOUNT',
    'EDIT_CUSTOMER_ACCOUNT',
    'PRODUCT_REVIEW',
    'NEWSLETTER',
    'CHECKOUT',
    null,
]);

export const ReCaptchaConfigEnum = PropTypes.oneOf([
    'V2_CHECKBOX',
    'V2_INVISIBLE',
    'V3_INVISIBLE',
]);

export const LocationType = PropTypes.shape({
    id: LocationEnum,
    type: ReCaptchaConfigEnum,
});

export const LocationsArrayType = PropTypes.arrayOf(LocationType);

export const ReCaptchaConfigType = PropTypes.shape({
    type: ReCaptchaConfigEnum,
    sitekey: PropTypes.string,
    theme: PropTypes.string,
    hl: PropTypes.string,
    size: PropTypes.string,
    badge_position: PropTypes.string,
});

export const ReCaptchaConfigArrayType = PropTypes.arrayOf(ReCaptchaConfigType);
