/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/GoogleRecaptcha/Query/ReCaptcha/Query */
export class ReCaptchaQuery {
    getData() {
        return new Field('googleReCaptcha')
            .addField(this.getLocation())
            .addField(this.getTypesConfig());
    }

    getLocation() {
        return new Field('locations')
            .addFieldList(this._getLocationFieldList());
    }

    getTypesConfig() {
        return new Field('types_config')
            .addFieldList(this._getTypesConfigFieldList());
    }

    _getLocationFieldList() {
        return [
            'id',
            'type',
        ];
    }

    _getTypesConfigFieldList() {
        return [
            'type',
            'sitekey',
            'theme',
            'hl',
            'size',
            'badge_position',
        ];
    }
}

export default new ReCaptchaQuery();
