/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { IS_KCO_ENABLED } from '../../config';
import { UPDATE_KLARNA_CONFIG } from '../../store/Klarna.action';

const getInitialState = (args, callback) => (
    {
        ...callback(...args),
        klarnaConfig: {
            failureUrl: '',
            updateKlarnaOrderUrl: '',
            getAddressesUrl: '',
            frontEndShipping: false,
            paymentMethod: '',
            acceptTermsUrl: '',
            userTermsUrl: '',
            prefillNoticeEnabled: false,
            methodUrl: '',
            isKssEnabled: false,
            updateKssStatusUrl: '',
            updateKssDiscountOrderUrl: '',
            isKcoEnabled: '',
            klarnaOnSiteMessagingConfig: {
                enabled: false,
                dataId: '',
                productEnabled: false,
                productPlacementSelect: '',
                theme: 'default',
                locale: '',
                cartEnabled: false,
                cartPlacementSelect: '',
                testMode: true,
            },
        },
    }
);

const addKlarnaToConfigReducer = (args, callback) => {
    const [state, action] = args;
    const {
        type,
        config: {
            klarnaConfig,
        } = {},
    } = action;

    if (type !== UPDATE_KLARNA_CONFIG) {
        return callback(...args);
    }

    // vvv saving in local storage if klarna checkout is enabled due to page refresh
    const { isKcoEnabled } = klarnaConfig || {};
    BrowserDatabase.setItem(isKcoEnabled, IS_KCO_ENABLED);

    return {
        ...state,
        klarnaConfig,
    };
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: getInitialState,
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: addKlarnaToConfigReducer,
    },
};
