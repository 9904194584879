/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';
import { RouterItemType } from 'Component/Router/Router.config';
import MyAccount from 'Route/MyAccount';
import { MyAccountTabsSection } from 'Type/Account.type';

import { PRIVACY_SETTINGS, PRIVACY_SETTINGS_URL } from '../config';
import PrivacySettingsPage from '../route/PrivacySettingsPage';

/**
 * override the property to append the render function to the render map.
 */
const renderMap = (member) => ({
    ...member,
    [PRIVACY_SETTINGS]: PrivacySettingsPage,
});

/**
 * override to render privacy settings link in the sidebar links
 */
const tabMap = (member) => ({
    ...member,
    [PRIVACY_SETTINGS]: {
        url: PRIVACY_SETTINGS_URL,
        tabName: __('Privacy Settings'),
        section: MyAccountTabsSection.SECOND_SECTION,
        isFullUrl: true,
    },
});

/**
 * adding new route for my account privacy settings
 */

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const switchItemsType = (member) => [
    ...member,
    {
        component: <Route
          path={ withStoreRegex(PRIVACY_SETTINGS_URL) }
          // vvv disabled eslint because we are mimicking the same shape of the objects in the
          // source scandipwa.
          // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
          render={ (props) => <MyAccount { ...props } selectedTab={ PRIVACY_SETTINGS } /> }
        />,
        position: 999,
        name: PRIVACY_SETTINGS,
    },
];

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap,
        },
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap,
        },
    },
    'Component/Router/Component': {
        'member-property': {
            [RouterItemType.SWITCH_ITEMS_TYPE]: switchItemsType,
        },
    },
};
