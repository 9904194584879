/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandiweb/AdobePageBuilder/Component/Map/Component */
export class MapComponent extends PureComponent {
  static propTypes = {
      locations: PropTypes.arrayOf(
          PropTypes.shape({
              position: PropTypes.shape({
                  lat: PropTypes.number.isRequired,
                  lng: PropTypes.number.isRequired,
              }).isRequired,
              location_name: PropTypes.string.isRequired,
              phone: PropTypes.string.isRequired,
              address: PropTypes.string.isRequired,
              city: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              zipcode: PropTypes.string.isRequired,
              country: PropTypes.string.isRequired,
              comment: PropTypes.string.isRequired,
              record_id: PropTypes.number.isRequired,
              areaAddress: PropTypes.string.isRequired,
          })
      ).isRequired,
      zoom: PropTypes.number.isRequired,
      googleMapsStyle: PropTypes.string.isRequired,
  };

  componentDidMount() {
      this.renderMap();
  }

  componentWillUnmount() {
      this.removeEventListeners();
  }

  removeEventListeners() {
      this.markerListeners.forEach(({ marker, infowindow }) => {
          if (typeof marker === 'function') {
              marker.removeListener('click', () => {
                  infowindow.close();
              });
          }
      });
  }

  renderLocation(location) {
      const {
          location_name,
          comment,
          phone,
          address,
          areaAddress,
          country,
      } = location;

      return (`
          <div>
              <h3>${ location_name }</h3>
              <p>${ comment }</p>
              <p>${ __('Phone: %s', phone) }</p>
              <p>
                  <span>
                      ${ address }
                      <br />
                      ${ areaAddress }
                      <br />
                      ${ country }
                  </span>
              </p>
          </div>
        `);
  }

  renderMarker(lng, lat, map) {
      // eslint-disable-next-line no-undef
      return new google.maps.Marker({
          map,
          position: { lng, lat },
      });
  }

  renderInfoWindow(location, location_name) {
      // eslint-disable-next-line no-undef
      return new google.maps.InfoWindow({
          content: this.renderLocation(location),
          ariaLabel: location_name,
      });
  }

  onMarkerClick(map, marker, infowindow) {
      marker.addListener('click', () => {
          infowindow.open({
              anchor: marker,
              map,
          });
      });

      // Save marker and infowindow references.
      this.markerListeners.push({ marker, infowindow });
  }

  async renderMap() {
      const { google } = window;
      const { Map } = await google.maps.importLibrary('maps');
      const { locations, zoom, googleMapsStyle } = this.props;

      if (!locations) {
          return '';
      }

      this.markerListeners = [];

      const { position: { lng, lat } } = locations[0];
      const map = new Map(document.getElementById('map'), {
          center: { lat, lng },
          zoom,
          styles: JSON.parse(googleMapsStyle),

      });

      locations.forEach((location) => {
          const { position: { lng, lat }, location_name } = location;
          const marker = this.renderMarker(lng, lat, map);
          const infowindow = this.renderInfoWindow(location, location_name);

          this.onMarkerClick(map, marker, infowindow);
      });

      return '';
  }

  render() {
      return <div id="map" />;
  }
}

export default MapComponent;
