/* eslint-disable react/forbid-elements */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { isCrawler } from '@scandipwa/scandipwa/src/util/Browser';

import CountrySelectorPopup from 'Component/CountrySelectorPopup/CountrySelectorPopup.component';
import MarketingPopup from 'Component/MarketingPopup/MarketingPopup.component';
import {
    AppComponent as SourceAppComponent,
} from 'SourceComponent/App/App.component';
import CountryRedirectUtility from 'Util/CountryRedirectUtility';

/** @namespace Inov8/Component/App/Component */
export class AppComponent extends SourceAppComponent {
    __construct(props: unknown) {
        super.__construct(props);

        this.state = {
            isSomethingWentWrong: false,
        };
    }

    componentDidMount() {
        CountryRedirectUtility.checkAndRedirectIfNeeded();
    }

    handleErrorReset(): void {
        this.setState({ isSomethingWentWrong: false });
    }

    renderRootComponents() {
        const baseComponents = super.renderRootComponents();

        return (
            <>
                { baseComponents }
                { (!isCrawler()) ? <CountrySelectorPopup /> : null }
                { (!isCrawler()) ? <MarketingPopup key="marketing-popup" /> : null }
            </>
        );
    }
}

export default AppComponent;
