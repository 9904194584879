/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import CheckboxesComponent from './Checkboxes.component';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container */
export class CheckboxesContainer extends DataContainer {
    /** defines the props for this container */
    static propTypes = {
        scope: PropTypes.string.isRequired,
    };

    state = {
        CheckboxGroupData: [],
    };

    static contextType = GDPRContext;

    async getData() {
        const { handleCheckScope } = this.context;
        const { scope } = this.props;
        const data = await handleCheckScope(scope);
        this.setState({ CheckboxGroupData: data });
    }

    componentDidMount() {
        this.getData();
    }

    containerProps() {
        const {
            scope,
        } = this.props;
        const {
            CheckboxGroupData,
        } = this.state;

        return {
            scope,
            CheckboxGroupData,
        };
    }

    render() {
        return (
            <CheckboxesComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxesContainer);
