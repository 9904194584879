/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/forbid-elements */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/* eslint-disable max-len */

// import { Component } from 'react';
import FooterConsent from 'Component/FooterConsent';
import PaymentMethodsImgs from 'Component/PaymentMethodImg';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';
import { FooterRenderColumn, FooterRenderColumnItem } from 'SourceComponent/Footer/Footer.type';
import Image from 'SourceComponent/Image';
import Link from 'SourceComponent/Link';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';
import { ReactElement } from 'SourceType/Common.type';
import { getStoreState } from 'SourceUtil/Store';

import {
    COLUMN_MAP, COUNTRIES,
    LEGAL_LINKS, SOCIALS,
} from './Footer.config';

import './Footer.style';

/** @namespace Inov8/Component/Footer/Component/isSingleValue */
export function isSingleValue<T>(value: T | readonly T[]): value is T {
    return !Array.isArray(value);
}

// Countries to appear at the top of the dropdown
export const PREFERRED_COUNTRIES = ['United Kingdom', 'United States', 'Australia', 'Austria', 'Canada', 'France', 'Germany', 'Netherlands', 'Norway', 'Sweden', 'Switzerland', 'Belgium', 'Bulgaria', 'Croatia', 'Denmark', 'Estonia', 'Finland', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Luxembourg', 'Monaco', 'Poland', 'Portugal', 'Romania', 'San Marino', 'Slovakia', 'Slovenia', 'Spain'];

/** @namespace Inov8/Component/Footer/Component/getOrderedCountries */
export const getOrderedCountries = (countries: any[]) => {
    const preferred = PREFERRED_COUNTRIES.map((pCountry) => countries.find((c) => c.label === pCountry)).filter(Boolean);
    const rest = countries.filter((c) => !PREFERRED_COUNTRIES.includes(c.label));

    rest.sort((a, b) => a.label.localeCompare(b.label));

    return [...preferred, ...rest];
};

/** @namespace Inov8/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    getDefaultCountry = () => {
        const { ConfigReducer: { code } = {} } = getStoreState();
        const defaultCountry = COUNTRIES.find((country) => code === country.value);

        return defaultCountry ? defaultCountry.value : '';
    };

    renderColumns(): ReactElement {
        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Footer', elem: 'Columns' } }
              label=""
            >
                { COLUMN_MAP.map(this.renderColumn.bind(this)) }
            </ContentWrapper>
        );
    }

    renderColumn(column: FooterRenderColumn, i?: number): ReactElement {
        const {
            title,
            columnActiveKey,
            items,
            isItemsHorizontal,
            mods = {},
        } = column;

        const {device: {isMobile}} = this.props;

        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        if (isMobile) {
            return (
                <details block="Footer" elem="Column" mods={ mods } key={ i }>
                    <summary>
                    <h3 block="Footer" elem="ColumnTitle">
                        { title }
                    </h3>
                    </summary>
                    <div
                      block="Footer"
                      elem="ColumnContent"
                      mods={ contentMods }
                    >
                    { items.map(this.renderColumnItem.bind(this)) }
                    </div>
                </details>
            );
        }

        return (
            <div block="Footer" elem="Column" mods={ mods } key={ i }>
                <h3 block="Footer" elem="ColumnTitle">
                    { title }
                </h3>
                <div
                  block="Footer"
                  elem="ColumnContent"
                  mods={ contentMods }
                >
                    { items.map(this.renderColumnItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderColumnItem(item: FooterRenderColumnItem, i: number): ReactElement {
        const { render } = item;
        const { ConfigReducer: { code } = {} } = getStoreState();
        const priceMatchPromise = ['uk', 'us'];

        if (code && !priceMatchPromise.includes(code) && item.title === 'Price Match Promise') {
            return null;
        }

        if (render && render in this.renderMap) {
            return this.renderMap[render as keyof typeof this.renderMap].render();
        }

        return this.renderColumnItemLink(item, i);
    }

    renderLegals(): ReactElement {
        const { ConfigReducer: { code } = {} } = getStoreState();

        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Footer', elem: 'BottomColumns' } }
              label=""
            >
                { LEGAL_LINKS.map((legal) => (
                    code && legal.available_in.includes(code) && (
                    <Link
                      block="Footer"
                      elem="LegalLinks"
                      to={ legal.href }
                      key={ legal.title }
                      aria-label={ legal.title }
                    >
                        { legal.title }
                    </Link>
                    )
                )) }
            </ContentWrapper>
        );
    }

    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }

    renderTopContent(): ReactElement {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'TopContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'TopContent' } }
              label=""
            >
                { this.renderNewsletterSubscriptionBlock() }
            </ContentWrapper>
        );
    }

    renderSocials(): ReactElement {
        const { ConfigReducer: { secure_base_media_url, code } = {} } = getStoreState();

        return (
            <div block="Footer" elem="Socials">
                { SOCIALS.map((social) => (
                    code && social.available_in.includes(code) && (
                    <Link
                      block="Footer"
                      elem="SocialLink"
                      to={ social.href }
                      mods={ social.src ? { type: 'image' } : undefined }
                      key={ social.title }
                      aria-label={ social.title }
                    >
                        <Image
                          mix={ { block: 'Footer', elem: 'SocialImage' } }
                          src={ secure_base_media_url + social.src }
                          title={ social.title }
                        />
                    </Link>
                    ))) }
            </div>
        );
    }

    renderBottomContent(): ReactElement {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'BottomWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'Bottom' } }
              label=""
            >
                <div block="foot-socials">
                { this.renderSocials() }
                { this.renderCopyrightContent() }
                </div>

            <FooterConsent />
            </ContentWrapper>
        );
    }

    render(): ReactElement {
        return (
            <RenderWhenVisible>
                <footer block="Footer" aria-label="Footer">
                    { this.renderTopContent() }
                    { this.renderContent() }
                    <div block="Footer" elem="BottomContent">
                        { this.renderLegals() }
                        <PaymentMethodsImgs />
                    </div>
                    { this.renderBottomContent() }
                </footer>
            </RenderWhenVisible>
        );
    }
}

export default FooterComponent;
