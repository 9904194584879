/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AmastyFreeGiftProvider } from '../../context/AmastyFreeGift';

const addAmastFreeGiftContextProvider = (member) => [
    (children) => (
         <AmastyFreeGiftProvider>
             { children }
         </AmastyFreeGiftProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAmastFreeGiftContextProvider
        }
    }
};
