/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_POPUP_TITLE } from '../../../store/Popup/Popup.action';

/*
 *  added possibility to dynamicaly update popup title
 *  to support extensions multiple selection method feature
 *  ( dynamicaly display number of remaining selectable gifts in popup title )
 */
const addUpdatePopupTitleToReducer = (args, callback) => {
    const result = callback(...args);
    const [state, action] = args;
    const { popupPayload = {} } = state || {};

    const {
        type,
        title,
        overlayKey
    } = action;

    if (type !== UPDATE_POPUP_TITLE) {
        return result;
    }

    return {
        ...result,
        popupPayload: {
            ...popupPayload,
            [overlayKey]: {
                ...popupPayload[overlayKey],
                title
            }
        }
    };
};

export default {
    'Store/Popup/Reducer/PopupReducer': {
        function: addUpdatePopupTitleToReducer
    }
};
