/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { FieldEvents, FieldReactEvents, FieldSelectCustomEvents } from '@scandipwa/scandipwa/src/component/Field/Field.type';
import FieldSelectContainer from '@scandipwa/scandipwa/src/component/FieldSelect';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { InputHTMLAttributes, SelectHTMLAttributes } from 'react';

import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';

import './Field.style';

/** @namespace Inov8/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    state = {
        value: '',
        hasContent: false,
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({
            value, // This will update the state with the input's new value
            hasContent: value.trim().length > 0, // Update hasContent state based on the new value
        });
    };

    handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ hasContent: value.trim().length > 0 });
    };

    handleInputFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ hasContent: value.trim().length > 0 });
    };

    handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ value });
    };

    updateHasContentState = (value: string) => {
        const hasContent = value.trim().length > 0;

        if (this.state.hasContent !== hasContent) {
            this.setState({ hasContent });
        }
    };

    renderErrorMessage(message: string, key: string): ReactElement {
        return <span block="Field" elem="ErrorMessage" key={ key }>{ message }</span>;
    }

    renderDefaultInput(): ReactElement {
        const {
            type, setRef, attr, events, isDisabled,
        } = this.props;
        const { hasContent, value } = this.state;

        if (!hasContent && attr.defaultValue) {
            if (typeof attr.defaultValue === 'string') {
                this.updateHasContentState(attr.defaultValue);
            }
        }

        return (
            <div block="Field" elem="input-wrapper">
                <input
                  id={ attr.name }
                  ref={ setRef }
                  disabled={ isDisabled }
                  type={ type }
                  onChange={ this.handleInputChange }
                  onBlur={ this.handleInputBlur }
                  onFocus={ this.handleInputFocus }
                  onInput={ this.handleInput }
                  value={ value }
                  { ...attr as InputHTMLAttributes<HTMLInputElement> }
                  { ...events as FieldReactEvents<HTMLInputElement> }
                />
                <label
                  htmlFor={ attr.name }
                  block={ hasContent ? 'has-content' : '' }
                >
                    { attr.placeholder }
                    { this.renderRequiredTag() }
                </label>
            </div>
        );
    }

    renderRequiredTag(): ReactElement {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="RequiredTag" mods={ { isRequired: true } }>
                { '  *' }
            </span>
        );
    }

    renderSubLabel(): ReactElement {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="Field" elem="SubLabelContainer">
                <div block="Field" elem="SubLabel">
                    { subLabel }
                    { this.renderRequiredTag() }
                </div>
            </div>
        );
    }

    renderSelect(): ReactElement {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
        } = this.props;

        return (
            <FieldSelectContainer
              attr={ attr as SelectHTMLAttributes<HTMLSelectElement> }
              events={ events as Omit<FieldEvents, 'onChange'> & FieldSelectCustomEvents }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isSortSelect={ isSortSelect }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
            />
        );
    }

    render(): ReactElement {
        const {
            type, validationResponse, mix,
        } = this.props;

        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);

        const {hasContent} = this.state;
        const isValid = hasContent;
        const hasError = validationResponse !== true && Object.keys(validationResponse || {}).length !== 0;

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid,
                      hasError,
                  } }
                  mix={ mix }
                >
                    { inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
