/**
 * Stripe compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_ADDRESS_INFO } from './FailedAddressInformation.action';

/** @namespace Scandiweb/Stripe/Store/FailedAddressInformation/Reducer/getInitialState */
export const getInitialState = () => ({
    addressInfo: {},
});

/** @namespace Scandiweb/Stripe/Store/FailedAddressInformation/Reducer/FailedAddressInformationReducer */
export const FailedAddressInformationReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_ADDRESS_INFO:
        const { addressInfo } = action;

        return {
            ...state,
            addressInfo,
        };
    default:
        return state;
    }
};

export default FailedAddressInformationReducer;
