/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Link from 'Component/Link';

import { CheckboxItemDataType } from '../../type/gdpr.type';

import './Checkbox.style';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Component */
export class CheckboxComponent extends PureComponent {
    static propTypes = {
        data: CheckboxItemDataType,
        handleCheckboxChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        data: {},
    };

    renderLink(name, consent_text) {
        return (
            <p block={ name }>
                { consent_text }
            </p>
        );
    }

    render() {
        const {
            data: {
                name,
                consentText,
                isEnabled,
                isRequired,
                link,
            } = {},
            handleCheckboxChange,
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <span
              block="GDPR"
              elem="Checkbox"
            >
                <Field
                  type={ FieldType.CHECKBOX }
                  label={ this.renderLink(name, consentText) }
                  attr={ { name } }
                  events={ { onChange: handleCheckboxChange } }
                  validationRule={ { isRequired } }
                />

                <Link
                  isOpenInNewTab
                  block="GDPRCheckbox"
                  elem="Link"
                  to={ `/${link}` }
                >
                    { name }
                </Link>
            </span>
        );
    }
}

export default CheckboxComponent;
