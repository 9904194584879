import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountForgotPasswordSuccessContainer as SourceMyAccountForgotPasswordSuccessContainer,
} from 'SourceComponent/MyAccountForgotPasswordSuccess/MyAccountForgotPasswordSuccess.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Component/MyAccountForgotPasswordSuccess/Container */
export class MyAccountForgotPasswordSuccessContainer extends SourceMyAccountForgotPasswordSuccessContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordSuccessContainer);
