/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { ElementType, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import MyAccountInformation from 'Component/MyAccountInformation';
import MyAccountOrder from 'Component/MyAccountOrder';
import MyAccountTabList from 'Component/MyAccountTabList';
import NoMatch from 'Route/NoMatch';
import {
    MyAccountAddressBook as SourceMyAccountAddressBook,
    MyAccountComponent as SourceMyAccountComponent,
    MyAccountDashboard as SourceMyAccountDashboard,
    MyAccountDownloadable as SourceMyAccountDownloadable,
    MyAccountMyOrders as SourceMyAccountMyOrders,
    MyAccountMyWishlist as SourceMyAccountMyWishlist,
    MyAccountNewsletterSubscription as SourceMyAccountNewsletterSubscription,
} from 'SourceRoute/MyAccount/MyAccount.component';
import { AccountPageUrl } from 'SourceRoute/MyAccount/MyAccount.config';
import { MyAccountTabs } from 'Type/Account.type';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import './MyAccount.style';

// TODO: implement MyAccountAddressBook
export const MyAccountAddressBook = SourceMyAccountAddressBook;

// TODO: implement MyAccountDashboard
export const MyAccountDashboard = SourceMyAccountDashboard;

// TODO: implement MyAccountDownloadable
export const MyAccountDownloadable = SourceMyAccountDownloadable;

// TODO: implement MyAccountMyOrders
export const MyAccountMyOrders = SourceMyAccountMyOrders;

// TODO: implement MyAccountMyWishlist
export const MyAccountMyWishlist = SourceMyAccountMyWishlist;

// TODO: implement MyAccountNewsletterSubscription
export const MyAccountNewsletterSubscription = SourceMyAccountNewsletterSubscription;

/** @namespace Inov8/Route/MyAccount/Component */
export class MyAccountComponent extends SourceMyAccountComponent {
    renderMap = {
        [ MyAccountTabs.MY_ACCOUNT ]: MyAccountDashboard,
        [ MyAccountTabs.MY_ORDER ]: MyAccountOrder,
        [ MyAccountTabs.MY_ORDERS ]: MyAccountMyOrders,
        [ MyAccountTabs.MY_WISHLIST ]: MyAccountMyWishlist,
        [ MyAccountTabs.ADDRESS_BOOK ]: MyAccountAddressBook,
        [ MyAccountTabs.NEWSLETTER_SUBSCRIPTION ]: MyAccountNewsletterSubscription,
        [ MyAccountTabs.ACCOUNT_INFORMATION ]: MyAccountInformation,
    } as unknown as Record<string, ElementType>;

    getTabContent(): ElementType {
        const { activeTab, location: { pathname } } = this.props;

        if (activeTab === MyAccountTabs.MY_ORDERS && pathname.includes(AccountPageUrl.ORDER_URL)) {
            return this.renderMap[ MyAccountTabs.MY_ORDER ];
        }

        return this.renderMap[ activeTab ];
    }

    renderContent(): ReactElement {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            onSignOut,
            isEditingActive,
            match,
            changeTabName,
            // tabName,
            setTabSubheading,
            isTabEnabled,
        } = this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        if (!isTabEnabled(activeTab)) {
            return <NoMatch />;
        }

        const TabContent = this.getTabContent();

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div
                  block="MyAccount"
                  elem="TabContent"
                  mods={ { activeTab } }
                >
                    <Suspense fallback={ <Loader /> }>
                        <TabContent
                          isEditingActive={ isEditingActive }
                          match={ match }
                          changeTabName={ changeTabName }
                          tabMap={ tabMap }
                          setTabSubheading={ setTabSubheading }
                        />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }
}

export default MyAccountComponent;
