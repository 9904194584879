/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    RECAPTCHA_LOCATION,
    RECAPTCHA_TOKENS,
    V2_CHECKBOX,
}
    from '../config/ReCaptcha.config';

/** @namespace Scandiweb/GoogleRecaptcha/Util/ReCaptcha/Util/loadScript */
export const loadScript = (id, src) => {
    const loadedScript = document.getElementById(id);

    if (loadedScript) {
        return;
    }

    const script = document.createElement('script');

    script.setAttribute('type', 'application/javascript');
    script.setAttribute('src', src);
    script.setAttribute('async', true);
    script.setAttribute('id', id);

    document.head.appendChild(script);
};

/** @namespace Scandiweb/GoogleRecaptcha/Util/ReCaptcha/Util/setTokens */
export const setTokens = (token, location) => {
    const oldTokens = JSON.parse(sessionStorage.getItem(RECAPTCHA_TOKENS));

    const mergedTokens = {
        ...oldTokens,
        [location]: token,
    };

    const stringifiedTokens = JSON.stringify(mergedTokens);

    window.sessionStorage.setItem(RECAPTCHA_TOKENS, stringifiedTokens);
    window.sessionStorage.setItem(RECAPTCHA_LOCATION, location);
};

/** @namespace Scandiweb/GoogleRecaptcha/Util/ReCaptcha/Util/isCheckbox */
export const isCheckbox = (type) => type === V2_CHECKBOX;

/** @namespace Scandiweb/GoogleRecaptcha/Util/ReCaptcha/Util/getTypeForLocation */
export const getTypeForLocation = (locations, locationId) => {
    const { type } = locations.find(({ id }) => (id === locationId));

    return type;
};
