/* eslint-disable no-magic-numbers */
// FooterConsent.component.tsx

import { PureComponent } from 'react';

import { FooterConsentComponentProps } from './FooterConsent.type';

import './FooterConsent.style';

/** @namespace Inov8/Component/FooterConsent/Component */
export class FooterConsentComponent extends PureComponent<FooterConsentComponentProps> {
    state = {
        showLink: false,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showLink: true });
        }, 500);
    }

    render() {
        const {showLink} = this.state;

        if (!showLink) {
            return null;
        }

        return (
            <button block="termly-display-preferences">
                Consent Preferences
            </button>
        );
    }
}

export default FooterConsentComponent;
