/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import SettingsModalComponent from './SettingsModal.component';

/** @namespace Scandiweb/AmastyGdpr/Component/SettingsModal/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
});

/** @namespace Scandiweb/AmastyGdpr/Component/SettingsModal/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/SettingsModal/Container */
export class SettingsModalContainer extends DataContainer {
    static propTypes = {};

    static contextType = GDPRContext;

    containerProps() {
        const {
            groupData,
            handleOnChangeChecked,
            isSettingsModalVisible,
        } = this.context;

        return {
            groupData,
            handleOnChangeChecked,
            isSettingsModalVisible,
        };
    }

    handleDoneOnClick() {
        const {
            dismissSettingsModal,
            handleUpdateSelectedCookies,
        } = this.context;

        handleUpdateSelectedCookies();
        dismissSettingsModal();
    }

    containerFunctions = {
        handleDoneOnClick: this.handleDoneOnClick.bind(this),
    };

    render() {
        return (
            <SettingsModalComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModalContainer);
