/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY } from '../../config';
import { cookiesGroupDataType } from '../../type/gdpr.type';

import './CookiesDetailsModal.style';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesDetailsModal/Component */
export class CookiesDetailsModalComponent extends PureComponent {
    /**
     * defines the props that could be passed for this component.
     */
    static propTypes = {
        groupData: cookiesGroupDataType,
        dismissPopup: PropTypes.func.isRequired,
        isCookiesDetailsModalVisible: PropTypes.bool.isRequired,
    };

    static defaultProps ={
        groupData: {
            name: '',
            description: '',
            groupId: '',
            isEssential: false,
            cookies: [],

        },
    };

    renderDescription() {
        const {
            groupData: {
                description,
            },
        } = this.props;

        return (
            <p>{ description }</p>
        );
    }

    renderTableHeader() {
        return (
            <tr>
                <th>{ __('Cookie Name') }</th>
                <th>{ __('Cookie Provider') }</th>
                <th>{ __('Cookie Description') }</th>
                <th>{ __('Cookie Lifetime') }</th>
                <th>{ __('Cookie Type') }</th>
            </tr>
        );
    }

    renderSingleCookie({
        name,
        provider,
        description,
        lifetime = '',
        type,
    }) {
        return (
            <tr key={ name }>
                <td>{ name }</td>
                <td>{ provider }</td>
                <td>{ description }</td>
                <td>{ lifetime }</td>
                <td>{ type }</td>
            </tr>
        );
    }

    renderTable() {
        const {
            groupData: {
                cookies,
            },
        } = this.props;

        if (!cookies) {
            return null;
        }

        return (
            <div block="GDPRCookiesDetails" elem="Table">
                <table>
                    <thead>
                        { this.renderTableHeader() }
                    </thead>
                    <tbody>
                        { cookies.map(this.renderSingleCookie) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderDoneButton() {
        const {
            dismissPopup,
        } = this.props;

        return (
            <button
              block="Button"
              onClick={ dismissPopup }
            >
                { __('Done') }
            </button>
        );
    }

    render() {
        const {
            groupData,
            isCookiesDetailsModalVisible,
        } = this.props;

        if (!groupData) {
            return null;
        }

        return (
            <Popup
              mix={ { block: 'GDPRCookiesDetails' } }
              id={ GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY }
              isCloseOnOutsideClick={ false }
              isStatic={ isCookiesDetailsModalVisible }
              isClosableOnKeydown={ false }
            >
                { this.renderDescription() }
                { this.renderTable() }
                { this.renderDoneButton() }
            </Popup>
        );
    }
}

export default CookiesDetailsModalComponent;
