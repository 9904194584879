/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getIndexedProducts } from 'Util/Product';

import { UPDATE_AM_PROMO_DATA } from '../../../store/Config/AmastyPromo.action';

const addAmPromoDataToInitialState = (args, callback) => ({
    ...callback(...args),
    amAdd: {},
    amItems: {}
});

/*
 * Added necessary data for amasty promo to ConfigReducer
 * because of problem plugging into CartReducer ( one of the actions overriding the whole state )
 * and not being able to add it to the context ( can't update context state from CartDispatcher )
*/
const getAmPromoDataFromAction = (args, callback) => {
    const result = callback(...args);
    const [, action] = args;

    const {
        type,
        amAdd,
        amItems
    } = action;

    if (type !== UPDATE_AM_PROMO_DATA) {
        return result;
    }

    const { products } = amItems;
    const indexedAmItems = {
        ...amItems,
        products: getIndexedProducts(products)
    };

    return {
        ...result,
        amAdd,
        amItems: indexedAmItems
    };
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addAmPromoDataToInitialState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getAmPromoDataFromAction
    }
};
