/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_KLARNA_CONFIG = 'UPDATE_KLARNA_CONFIG';

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Store/Klarna/Action/updateKlarnaConfig */
export const updateKlarnaConfig = (config) => ({
    type: UPDATE_KLARNA_CONFIG,
    config,
});
