/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { GDPR_POPUP_KEY } from '../../config';
import { GDPRContext } from '../../context/GDPR.context';
import SettingsModal from '../SettingsModal';

import './GDPR.style';

/** @namespace Scandiweb/AmastyGdpr/Component/GDPR/Component */
export class GDPRComponent extends PureComponent {
    /** defines the props for this component */
    static propTypes = {
        handleAcceptCookies: PropTypes.func.isRequired,
        handleDeclineCookies: PropTypes.func.isRequired,
        notificationText: PropTypes.string.isRequired,
        acceptBtnText: PropTypes.string,
        declineBtnIsEnabled: PropTypes.string.isRequired,
        declineBtnText: PropTypes.string,
        settingsBtnText: PropTypes.string,
        showSettingsModal: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isCookiesBarVisible: PropTypes.bool.isRequired,
        // todo: replace this type?
        // eslint-disable-next-line react/forbid-prop-types
        cookieBarStyle: PropTypes.object,
    };

    static defaultProps = {
        acceptBtnText: __('Accept'),
        declineBtnText: __('Decline'),
        settingsBtnText: __('Settings'),
        cookieBarStyle: {},
    };

    static contextType = GDPRContext;

    renderAcceptBtn() {
        const {
            acceptBtnText,
            handleAcceptCookies,
        } = this.props;

        return (
            <button
              mix={ { block: 'Button GDPR', elem: 'Button', mods: { Accept: true } } }
              onClick={ handleAcceptCookies }
            >
                 { acceptBtnText }
            </button>
        );
    }

    renderDeclineBtn() {
        const {
            declineBtnIsEnabled,
            declineBtnText,
            handleDeclineCookies,
        } = this.props;

        if (!declineBtnIsEnabled || declineBtnIsEnabled === '0') {
            return null;
        }

        return (
            <button
              mix={ { block: 'GDPR', elem: 'Button', mods: { Decline: true } } }
              onClick={ handleDeclineCookies }
            >
                { declineBtnText }
            </button>
        );
    }

    renderSettingsBtn() {
        const {
            settingsBtnText,
            showSettingsModal,
        } = this.props;

        return (
            <button
              mix={ { block: 'GDPR', elem: 'Button', mods: { Settings: true } } }
              onClick={ showSettingsModal }
            >
                 { settingsBtnText }
            </button>
        );
    }

    renderCookieBar() {
        const {
            notificationText,
            cookieBarStyle,
            isCookiesBarVisible,
            isLoading,
        } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        if (!notificationText) {
            return null;
        }

        const cookieBarLocation = cookieBarStyle['--gdpr-cookies-bar-location'];

        return (
            <Popup
              mix={ { block: `${cookieBarLocation} GDPR`, elem: 'Popup' } }
              id={ GDPR_POPUP_KEY }
              isCloseOnOutsideClick={ false }
              isStatic={ isCookiesBarVisible }
              isClosableOnKeydown={ false }
            >
                <div
                  mix={ { block: 'GDPR', elem: 'ContentWrapper', mods: { ConsentClassicBar: true } } }
                  style={ cookieBarStyle }
                >
                    <p mix={ { block: 'GDPR', elem: 'NotificationText' } }>
                        <Html content={ notificationText } />
                    </p>
                    <div mix={ { block: 'GDPR', elem: 'ButtonsWrapper' } }>
                        { this.renderAcceptBtn() }
                        { this.renderDeclineBtn() }
                        { this.renderSettingsBtn() }
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        const { isCookiesBarVisible } = this.props;

        if (!isCookiesBarVisible) {
            return <SettingsModal />;
        }

        return (
            <>
                { this.renderCookieBar() }
                <SettingsModal />
            </>
        );
    }
}

export default GDPRComponent;
