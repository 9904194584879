/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UrlRewritePageType } from 'Route/UrlRewrites/UrlRewrites.config';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';

export const DL_VAL_PAGE_HOME = 'homepage';
export const DL_VAL_PAGE_CART = 'cart';
export const DL_VAL_PAGE_CHECKOUT = 'checkout';
export const DL_VAL_PAGE_ACCOUNT = 'account';
export const DL_VAL_PAGE_WISHLIST = 'wishlist';
export const DL_VAL_PAGE_FORGOT = 'forgot';
export const DL_VAL_PAGE_CONTACT = 'contact';
export const DL_VAL_PAGE_COMPARE = 'compare';
export const DL_VAL_PAGE_BLOG = 'blog';
export const DL_VAL_PAGE_STORES = 'stores';
export const DL_VAL_PAGE_SEARCH = 'search';
export const DL_VAL_PAGE_CATEGORY = 'category';
export const DL_VAL_PAGE_PRODUCT = 'product';
export const DL_VAL_PAGE_404 = 'not_found';
export const DL_VAL_PAGE_CMS = 'cmspage';
export const PATH_NAME_MAP = {
    [DL_VAL_PAGE_CART]: '/cart',
    [DL_VAL_PAGE_CHECKOUT]: '/checkout',
    [DL_VAL_PAGE_ACCOUNT]: '/account',
    [DL_VAL_PAGE_WISHLIST]: '/wishlist',
    [DL_VAL_PAGE_FORGOT]: '/forgot-password',
    [DL_VAL_PAGE_CONTACT]: '/contact',
    [DL_VAL_PAGE_COMPARE]: '/compare',
    [DL_VAL_PAGE_BLOG]: '/blog',
    [DL_VAL_PAGE_STORES]: '/stores',
    [DL_VAL_PAGE_SEARCH]: '/search',
};

/** @namespace Scandiweb/Gtm/Data/Page/getPageData */
export const getPageData = async (urlRewriteData) => {
    // vvv Wait for store code
    await waitForCallback(() => getStore().getState().ConfigReducer?.store_code);

    const {
        ConfigReducer: {
            store_code,
        } = {},
    } = getStore().getState();

    const realPath = window.location.pathname.replace(
        `/${store_code}`,
        ''
    );

    // vvv Try looking up the page from an array of pre-defined ones
    if (!urlRewriteData) {
        if (realPath === '/') {
            return { pageType: DL_VAL_PAGE_HOME };
        }

        const [pathnameKey] = Object.entries(PATH_NAME_MAP).find(([, pathname]) => (
            realPath.indexOf(pathname) !== -1
        )) || [];

        return pathnameKey ? { pageType: pathnameKey } : false;
        // ^^^ false means It's not the pre-defined so 99% we're gonna have it from urlRewrites
    }

    const {
        type,
        notFound = false,
    } = urlRewriteData;

    if (notFound) {
        return { pageType: DL_VAL_PAGE_404 };
    }

    if (!type) {
        return { pageType: '' };
    }

    const typeToKeyMap = {
        [UrlRewritePageType.CMS_PAGE]: `${DL_VAL_PAGE_CMS}${realPath}`,
        [UrlRewritePageType.CATEGORY]: DL_VAL_PAGE_CATEGORY,
        [UrlRewritePageType.PRODUCT]: DL_VAL_PAGE_PRODUCT,
    };

    return { pageType: typeToKeyMap[type] };
};

// ^^^ vvv I am keeping these to make it easier to create a plugin or change it for custom use and also to avoid complicity.

/** @namespace Scandiweb/Gtm/Data/Page/getProductClickPageData */
export const getProductClickPageData = async (urlPathname, urlRewriteData, category = {}) => {
    // vvv Wait for store code
    await waitForCallback(() => getStore().getState().ConfigReducer?.store_code);

    const {
        ConfigReducer: {
            store_code,
        } = {},
    } = getStore().getState();

    const realPath = urlPathname.replace(
        `/${store_code}`,
        ''
    );

    // vvv Try looking up the page from an array of pre-defined ones
    if (realPath === '/' || realPath === '') {
        return { pageType: DL_VAL_PAGE_HOME };
    }

    const [pathnameKey] = Object.entries(PATH_NAME_MAP).find(([, pathname]) => (
        realPath.indexOf(pathname) !== -1
    )) || [];

    if (pathnameKey) {
        return { pageType: pathnameKey };
    }

    const {
        id: categoryId,
        name: categoryName,
    } = category;
    const {
        urlRewrite: {
            id: urlRewriteId = -1,
            type,
            notFound = false,
        },
    } = urlRewriteData;

    if (notFound) {
        return { pageType: DL_VAL_PAGE_404 };
    }

    if (!type) {
        return { pageType: '' };
    }

    const typeToKeyMap = {
        [UrlRewritePageType.CMS_PAGE]: `${DL_VAL_PAGE_CMS} - ${realPath}`,
        [UrlRewritePageType.CATEGORY]: urlRewriteId === categoryId
            ? `${DL_VAL_PAGE_CATEGORY} - ${categoryName}`
            : DL_VAL_PAGE_CATEGORY,
        [UrlRewritePageType.PRODUCT]: DL_VAL_PAGE_PRODUCT,
    };

    return { pageType: typeToKeyMap[type] };
};
