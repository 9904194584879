import {
    colSpanCounts as sourceColSpanCounts,
    colSpanCountsMobile as sourceColSpanCountsMobile,
} from 'SourceComponent/MyAccountOrderTotals/MyAccountOrderTotals.config';

// TODO: implement colSpanCounts
export const colSpanCounts = sourceColSpanCounts;

// TODO: implement colSpanCountsMobile
export const colSpanCountsMobile = sourceColSpanCountsMobile;
