import {
    FieldSelectContainer as SourceFieldSelectContainer,
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Inov8/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    // TODO implement logic
}

export default FieldSelectContainer;
