import Html from '@scandipwa/scandipwa/src/component/Html';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import {
    NotificationComponent as SourceNotificationComponent,
} from 'SourceComponent/Notification/Notification.component';

import './Notification.style';

/** @namespace Inov8/Component/Notification/Component */
export class NotificationComponent extends SourceNotificationComponent {
    render(): ReactElement | null {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;
        const location = useLocation();

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing',
        };

        return (
            isNotificationVisible ? (
                <div block={ location.pathname.split('/').pop() === 'shipping' ? 'hidden' : 'Notification' } mods={ mods } ref={ this.notification } id={ id }>
                    <button block="Notification" elem="Button" onClick={ this.hideNotification }> Close </button>
                    <p block="Notification" elem="Text">
                        <Html content={ msgText } />
                    </p>
                    { this.renderDebug() }
                </div>
            ) : null
        );
    }
}

export default NotificationComponent;
