/* eslint-disable max-lines */
import { Field, Query } from '@tilework/opus';

import {
    OrderQuery as SourceOrderQuery,
} from 'SourceQuery/Order.query';
import {
    CreditMemo,
    CustomerOrders,
    Invoice,
    Money,
    OrderAddress,
    OrderItem,
    OrderPaymentMethod,
    OrderProductSelectedOption,
    OrderShipment,
    OrdersOptions,
    OrderTotal,
    SalesCommentItem,
    SearchResultPageInfo,
} from 'SourceQuery/Order.type';
import {OptimizedProductImage} from 'SourceQuery/ProductList.type';

export interface OrderItemV2Product {
    product_url_key: string;
    quantity_ordered: number;
    quantity_shipped: number;
    quantity_refunded: number;
    quantity_canceled: number;
    quantity_returned: number;
    entered_options: OrderProductSelectedOption[];
    selected_options: OrderProductSelectedOption[];
    row_subtotal: Money;
    product_name: string;
    product_sku: string;
    product_sale_price: Money;
    product: Product;
}

export interface Product {
    thumbnail: OptimizedProductImage;
    small_image: OptimizedProductImage;
    image: OptimizedProductImage;
}

/** @namespace Inov8/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    getOrderListV2Query(options: Partial<OrdersOptions>): Query<'customer', { orders: CustomerOrders }> {
        return new Query<'customer', { orders: CustomerOrders }>('customer')
            .addFieldList(this._getOrderListV2Fields(options));
    }

    _getOrderListV2Fields(options: Partial<OrdersOptions>): Field<'orders', CustomerOrders>[] {
        return [
            this._getOrdersV2Field(options),
        ];
    }

    _getOrdersV2Field(options: Partial<OrdersOptions>): Field<'orders', CustomerOrders> {
        const { orderId = 0, page = 1 } = options || {};
        const ordersField = new Field<'orders', CustomerOrders>('orders');

        if (orderId) {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
                .addFieldList(this._getOrdersV2Fields(true));
        }

        return ordersField
            .addArgument('currentPage', 'Int', page)
            .addFieldList(this._getOrdersV2Fields());
    }

    _getOrdersV2Fields(isSingleOrder = false): Array<
    Field<'total_count', number>
    | Field<'items', OrderItem, true>
    | Field<'page_info', SearchResultPageInfo>
    > {
        return [
            new Field<'total_count', number>('total_count'),
            this._getOrderItemsV2Field(isSingleOrder),
            this._getSearchResultPageInfoField(),
        ];
    }

    _getOrderItemsV2Field(isSingleOrder: boolean): Field<'items', OrderItem, true> {
        return new Field<'items', OrderItem, true>('items', true)
            .addFieldList(this._getOrderItemsV2Fields(isSingleOrder));
    }

    _getOrderItemsV2Fields(isSingleOrder: boolean): Array<
    Field<'id', number>
    | Field<'increment_id', number>
    | Field<'order_date', string>
    | Field<'status', string>
    | Field<'can_reorder', boolean>
    | Field<'rss_link', string>
    | Field<'total', OrderTotal>
    | Field<'carrier', string>
    | Field<'shipments', OrderShipment, true>
    | Field<'items', OrderItemV2Product, true>
    | Field<'invoices', Invoice, true>
    | Field<'credit_memos', CreditMemo, true>
    | Field<'shipping_address', OrderAddress>
    | Field<'billing_address', OrderAddress>
    | Field<'payment_methods', OrderPaymentMethod, true>
    | Field<'shipping_method', string>
    | Field<'comments', SalesCommentItem, true>
    > {
        const basicFields = [
            new Field<'id', number>('id'),
            new Field<'increment_id', number>('increment_id'),
            new Field<'order_date', string>('order_date'),
            new Field<'status', string>('status'),
            new Field<'can_reorder', boolean>('can_reorder'),
            new Field<'rss_link', string>('rss_link'),
            this._getOrderItemTotalField(),
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderV2Fields()];
        }

        return basicFields;
    }

    _getSingleOrderV2Fields(): Array<
    Field<'carrier', string>
    | Field<'shipments', OrderShipment, true>
    | Field<'items', OrderItemV2Product, true>
    | Field<'invoices', Invoice, true>
    | Field<'credit_memos', CreditMemo, true>
    | Field<'shipping_address', OrderAddress>
    | Field<'billing_address', OrderAddress>
    | Field<'payment_methods', OrderPaymentMethod, true>
    | Field<'shipping_method', string>
    | Field<'comments', SalesCommentItem, true>
    > {
        return [
            new Field<'carrier', string>('carrier'),
            this._getOrderShipmentsField(),
            this._getOrderItemsV2ProductsField(),
            this._getOrderInvoicesField(),
            this._getOrderRefundsField(),
            this._getOrderShippingAddressField(),
            this._getOrderBillingAddressField(),
            this._getOrderPaymentMethodsField(),
            this._getOrderShippingMethodField(),
            this._getOrderCommentsField(),
        ];
    }

    _getOrderItemsV2ProductsField(): Field<'items', OrderItemV2Product, true> {
        return new Field<'items', OrderItemV2Product, true>('items', true)
            .addFieldList(this._getOrderItemV2ProductsFields());
    }

    _getOrderItemV2ProductsFields(): Array<
    Field<'product_url_key', string>
    | Field<'quantity_ordered', number>
    | Field<'quantity_shipped', number>
    | Field<'quantity_refunded', number>
    | Field<'quantity_canceled', number>
    | Field<'entered_options', OrderProductSelectedOption, true>
    | Field<'selected_options', OrderProductSelectedOption, true>
    | Field<'row_subtotal', Money>
    | Field<'product_name', string>
    | Field<'product_sku', string>
    | Field<'product_sale_price', Money>
    | Field<'product', {
        thumbnail: OptimizedProductImage;
        small_image: OptimizedProductImage;
        image: OptimizedProductImage;
    }>
    > {
        return [
            new Field<'product_url_key', string>('product_url_key'),
            new Field<'quantity_ordered', number>('quantity_ordered'),
            new Field<'quantity_shipped', number>('quantity_shipped'),
            new Field<'quantity_refunded', number>('quantity_refunded'),
            new Field<'quantity_canceled', number>('quantity_canceled'),
            this._getOrderProductEnteredOptionsField(),
            this._getOrderProductSelectedOptionsField(),
            this._getOrderProductRowSubtotalField(),
            ...this._getBaseOrderItemProductsFields(),
            this._getProductField(),
        ];
    }

    _getProductField(): Field<'product', {
        thumbnail: OptimizedProductImage;
        small_image: OptimizedProductImage;
        image: OptimizedProductImage;
    }> {
        return new Field<'product', {
            thumbnail: OptimizedProductImage;
            small_image: OptimizedProductImage;
            image: OptimizedProductImage;
        }>('product')
            .addFieldList(this._getProductFields());
    }

    _getProductThumbnailField(): Field<'thumbnail', OptimizedProductImage> {
        return new Field<'thumbnail', OptimizedProductImage>('thumbnail')
            .addFieldList(this._getProductThumbnailFields());
    }

    _getProductSmallField(): Field<'small_image', OptimizedProductImage> {
        return new Field<'small_image', OptimizedProductImage>('small_image')
            .addFieldList(this._getProductSmallFields());
    }

    _getProductImageField(): Field<'image', OptimizedProductImage> {
        return new Field<'image', OptimizedProductImage>('image')
            .addFieldList(this._getProductThumbnailFields());
    }

    /**
     * @returns {[string]} an array representing the subfields of the product thumbnail
     * @private
     */
    _getProductThumbnailFields(): Array<
    Field<'path', string>
    | Field<'url', string>
    > {
        return [
            new Field<'path', string>('path'),
            new Field<'url', string>('url'),
        ];
    }

    _getProductFields(): Array<
    Field<'thumbnail', OptimizedProductImage>
    | Field<'small_image', OptimizedProductImage>
    | Field<'image', OptimizedProductImage>
    > {
        return [
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getProductImageField(),
        ];
    }

    _getProductSmallFields(): Array<
    Field<'path', string>
    | Field<'url', string>
    > {
        return this._getProductThumbnailFields();
    }
}

export default new OrderQuery();
