import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import {
    MyAccountOverlayContainerProps,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.type';
import { RootState } from 'Util/Store/Store.type';
/** @namespace Inov8/Component/MyAccountOverlay/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/MyAccountOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Component/MyAccountOverlay/Container */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(
    MyAccountOverlayContainer as unknown as ComponentType<MyAccountOverlayContainerProps>,
);
