/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/AmastyGdpr/Util/Router/Util/cleanDuplicatesFromAfterItemsType */
export const cleanDuplicatesFromAfterItemsType = (members = []) => {
    const seenNames = {};

    return members.filter((component) => {
        if (Object.prototype.hasOwnProperty.call(seenNames, component.name)) {
            return false;
        }
        seenNames[component.name] = true;

        return true;
    });
};
